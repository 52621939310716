import {
    setNouns,
    setAdjectives,
    setTemplates,
    paragraph
} from 'txtgen'

// ==================================================================================== //
// ESOTERIC

const esoteric_nouns = ['enlightenment', 'dismay', 'fear', 'dread', 'revelation', 'god', 'sun', 'truth', 'day', 'judgement', 'tragedy', 'cowardice', 'disgust', 'blade', 'cloud', 'hatred', 'shadow', 'phantom', 'blight', 'void', 'key', 'prophecy', 'saviour', 'shell', 'sequence', 'anguish', 'hero', 'delight', 'lung', 'core', 'destruction', 'blood', 'hate', 'sigil', 'brand', 'delight', 'divinity'];
const esoteric_adjectives = ['revered', 'angered', 'feared', 'adored', 'quickened', 'banned', 'broken', 'sacred', 'banned', 'absolute', 'null', 'collective', 'dark', 'final', 'blinding', 'intrinsic', 'episodic', 'ternary', 'binary', 'ethereal', 'cursed', 'extreme', 'accursed', 'atrocious', 'vile', 'desecrated', 'holy', 'painful', 'hallowed', 'redeemed'];
const esoteric_templates = [
    'admire the {{adjective}} {{noun}} into {{an_adjective}} {{noun}} of the {{noun}}',
    'your {{noun}} represents {{a_noun}}, for it is {{an_adjective}} {{noun}}',
    'the {{adjective}} {{noun}} will reveal itself in their {{adjective}} {{noun}}',
    'the {{adjective}} {{noun}} will disappear in your {{adjective}} {{noun}}',
    'inside {{an_adjective}} {{noun}} resides the {{noun}} of {{an_adjective}} {{noun}}',
    'our {{adjective}} soul will perish with the {{adjective}} {{nouns}}',
    'our {{adjective}} {{noun}} is {{a_noun}} to {{adjective}} {{noun}}',
    'receive the {{adjective}} {{noun}}',
    'initiate the {{adjective}} {{noun}}',
    'they are {{adjective}} for their {{adjective}} {{noun}}',
    'the {{adjective}} {{noun}} will corrupt {{an_adjective}} {{noun}}',
    'they cannot realize that the {{adjective}} {{noun}} of {{a_noun}} prepares the {{adjective}} {{noun}} of the {{adjective}} {{noun}}',
    'it opens the {{adjective}} {{noun}}',
    'the {{adjective}} {{noun}} cannot be {{adjective}}',
    '{{adjective}} {{nouns}} must not be {{adjective}}',
    'break the {{adjective}} and your {{adjective}}, {{adjective}} {{noun}} will be {{adjective}}',
];

setNouns(esoteric_nouns);
setAdjectives(esoteric_adjectives);
setTemplates(esoteric_templates);

// main
let matches = document.getElementsByClassName('esoteric');
for (let item of matches) {
    item.innerText = paragraph(4);
}

// ==================================================================================== //
// UNVEIL

const obf = document.getElementById('obfuscator');

let obf_unveil_duration = 500;
let obf_unveil = [
    { opacity: 1 },
    { opacity: 0 },
];
let obf_unveil_timing = {
    duration: obf_unveil_duration,
    iterations: 1,
};

// wait for the whole page to load
window.addEventListener('load', () => {

    // animate
    obf.animate(obf_unveil, obf_unveil_timing);

    // deactivate after animation
    setTimeout(() => {
        obf.hidden = true;
        obf.setAttribute('opacity', 0);
    }, obf_unveil_duration - 1);
})
